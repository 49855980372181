/* eslint-disable */
import React from 'react';
import styles from './Kontakt.module.scss';
import { LanguageContext } from '../../contexts/LanguageContext';
import {Helmet} from "react-helmet";

const Kontakt = () =>(

    <LanguageContext.Consumer>{(context)=>{
        return(
            <>
            <Helmet>
                <title>{context.isPL ? 'Kontakt - fotografia, Szymon Karpiński, Bielsko-Biała' : 'Contakt - Szymon Karpiński'}</title>
                <meta name="description" content='Zapraszam do kontaktu'/>
            </Helmet>
            <div className={styles.kontakt}>
                {context.isPL ? 'Bielsko-Biała, Polska' : 'Bielsko-Biała, Poland'}
               <div><a href="www.szymonkarpinski.pl">karpinski_szymon(at)wp.pl</a></div>
                   <a href="tel:+48514942473"> (+48) 514-942-473</a>
               <p>©  {(new Date().getFullYear())} Szymon Karpiński </p>
            </div>
            </>        
        )
    }}

    </LanguageContext.Consumer>

);


export default Kontakt;