import React from 'react';
import styles from './GalleryScroll.module.scss'
import Slide from '../Slide/Slide';
import {Helmet} from "react-helmet";
import { LanguageContext } from '../../../contexts/LanguageContext';
import RichText from '../../RichText/RichText';


const GalleryScroll = ({slides, seo}) =>{
        
return(
    <LanguageContext.Consumer>{(context)=>{
        const {isPL} = context;
      
        return(
      <>
      <Helmet>
      <title>{seo.seoTitle}</title>
            <meta name="description" content={seo.seoDescription}/>
    </Helmet>
<div className={styles.wrapper}>
    

    <div className={styles.galleryScroll}>
        {slides.map((slide, i) =>(
            slide.photo ? (<Slide key={i} image={slide.photo.url} />
               
                ) : (
                <div className={styles.description}><RichText iteraction = {isPL ? slide.descriptionPl : slide.descriptionEn}/></div>
                )
            
            
            ))}
        
    </div>
</div>
</>
)}}
   </LanguageContext.Consumer> 
 ) }

export default GalleryScroll