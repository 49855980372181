import React from 'react';
import{ Link } from 'react-router-dom';
import styles from './H1.module.scss';
import { useLocation } from 'react-router-dom'


const H1 = () =>{
    const location = useLocation();
    return(
        location.pathname == '/' ? (
        <h1 className={styles.name}><Link className={styles.nameLink} to="/">Szymon <br className={styles.br}/>Karpiński</Link></h1>
        ):(  
        <span className={styles.name}><Link className={styles.nameLink} to="/">Szymon <br className={styles.br}/>Karpiński</Link></span>
        )
        
    )
    };

export default H1;